import React from "react";

const Input = ({ label, ...props }) => {
  return (
    <div className="flex flex-col gap-3 w-full">
      {label && <label>{label}</label>}
      {props.textarea ? (
        <textarea
          className="border-2 focus:outline-0 focus:border-2 focus:border-red px-3 py-2 text-black-300 w-full"
          {...props}
          rows="5"
        ></textarea>
      ) : (
        <input
          className="border-2 focus:outline-0 focus:border-2 focus:border-red px-3 py-2 text-black-300 w-full"
          {...props}
        />
      )}
    </div>
  );
};

export default Input;
