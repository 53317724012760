import React from "react";
import Button from "../../components/Button";
import { Link } from "gatsby";

const ServicesItem = ({ service }) => {
  return (
    <div className="w-[400px] px-10 py-5 odd:bg-black-100 even:bg-black-200 flex flex-col justify-between">
      <h5 className="text-2xl font-bold">{service.title}</h5>
      <p className="mt-5">{service.description}</p>
      <div className="mt-5 flex justify-start">
        <Link to={service.link}>
          <Button variant="primary">Read more</Button>
        </Link>
      </div>
    </div>
  );
};

export default ServicesItem;
