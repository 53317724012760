import React from "react";
import Button from "../../components/Button";
import DecorationRow from "../../components/DecorationRow";

const Hero = ({ onScrollTo }) => {
  return (
    <div
      className="w-full h-[100vh] flex items-center justify-center text-white flex-col gap-5 px-[5%] lg:px-[20%]"
      style={{
        background:
          "linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('./images/hero-bg.webp') no-repeat center center/cover",
      }}
    >
      <DecorationRow />
      <div className="text-center">
        <h1 className="uppercase font-black text-4xl md:text-7xl">
          <span className="text-red">Red</span> horse Security
        </h1>
        <h2 className="uppercase font-bold text-xl md:text-3xl mt-2">
          Your Shield in the Digital World
        </h2>
        <h3 className="mt-7">
          Protecting your digital assets with cutting-edge cybersecurity
          solutions. From penetration testing and red team as a service to
          comprehensive cybersecurity consultancy and innovative tools
          development, we ensure your business stays secure in an ever-evolving
          threat landscape. Trust us to fortify your defenses with expert OSINT
          analytics and proactive strategies.
        </h3>
        <div className="flex flex-col items-center justify-center md:flex-row gap-4 mt-10">
          <Button variant="outlined" onClick={() => onScrollTo("about")}>
            About
          </Button>
          <Button variant="primary" onClick={() => onScrollTo("contact")}>
            Contact us
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Hero;
