import * as React from "react";
import Hero from "../sections/Hero/Hero";
import About from "../sections/About/About";
import Importance from "../sections/Importance/Importance";
import Services from "../sections/Services/Services";
import Layout from "../components/Layout";
import Contact from "../sections/Contact/Contact";

const IndexPage = () => {
  const scrollToElement = (el) => {
    const element = document.getElementById(el);
    if (window.location.pathname === "/" && element) {
      window.history.pushState({}, "", `/#${el}`);
    }
    if (element) element.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <Layout onScrollTo={scrollToElement}>
      <Hero onScrollTo={scrollToElement} />
      <About />
      <Importance />
      <Services />
      <Contact />
    </Layout>
  );
};

export default IndexPage;
