import React from "react";
import SectionHeader from "../../components/SectionHeader";

const Importance = () => {
  return (
    <section id="about" className="px-[5%] py-10 bg-black-200 text-white">
      <SectionHeader title="The Importance of Cybersecurity" />
      <div
        className="mt-10 p-10 lg:p-20 flex gap-5 flex-col lg:flex-row"
        style={{
          background:
            "linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('./images/importance-bg.webp') no-repeat center center/cover",
        }}
      >
        <span className="material-symbols-outlined text-red text-7xl">
          security
        </span>
        In today's digital age, cybersecurity is crucial for protecting
        sensitive data and maintaining trust. Cyber threats, such as hacking,
        data breaches, and malware, can lead to significant financial losses and
        damage reputations. Effective cybersecurity measures safeguard personal
        information, ensure business continuity, and defend against evolving
        threats. Prioritizing cybersecurity is essential for securing the
        digital infrastructure of individuals, businesses, and governments.
      </div>
    </section>
  );
};

export default Importance;
