import React from "react";
import SectionHeader from "../../components/SectionHeader";
import { Link } from "gatsby";
import Button from "../../components/Button";

const About = () => {
  return (
    <section id="about" className="px-[5%] py-10 bg-red text-white">
      <div className="mt-10 bg-black-300 px-20 py-10 flex flex-col lg:flex-row gap-10 items-center">
        <div className="hidden lg:block">
          <img alt="about" src="./images/about.png" width="1000px" />
        </div>
        <div>
          <div>
            <SectionHeader title="About us" color="white" />
            <p className="mt-10">
              At{" "}
              <span className="font-black uppercase">
                <span className="text-red">RED</span> HORSE SECURITY
              </span>
              , we are dedicated to safeguarding your digital assets in an
              ever-evolving threat landscape. With a team of seasoned
              cybersecurity experts, we provide comprehensive solutions tailored
              to meet the unique needs of your business.
            </p>
          </div>
          <div className="mt-7">
            <h4 className="text-2xl font-medium uppercase">Our mission</h4>
            <p className="mt-2">
              Our mission is to empower organizations with the knowledge and
              tools necessary to defend against cyber threats. We believe in
              proactive security measures and continuous improvement to ensure
              your business stays ahead of potential risks.
            </p>
          </div>
          <div className="mt-10 flex justify-start">
            <Link to="/about">
              <Button variant="primary">Read more</Button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
