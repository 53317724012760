import React from "react";
import classNames from "classnames";

const Button = ({ children, className, variant, ...props }) => {
  return (
    <button
      {...props}
      className={classNames({
        "px-7 py-3 uppercase transition-all font-black box-border": true,
        "bg-red border-2 border-red text-white hover:bg-black-400 hover:border-black-400":
          variant === "primary",
        "text-white border-2 border-white hover:text-black-400 hover:bg-white":
          variant === "outlined",
        [className]: !!className,
      })}
    >
      {children}
    </button>
  );
};

export default Button;
