import React from "react";
import SectionHeader from "../../components/SectionHeader";
import Input from "../../components/Input";
import Button from "../../components/Button";

const Contact = () => {
  const submitFormHandler = (event) => {
    event.preventDefault();
  };

  return (
    <section id="contact" className="px-[5%] py-10 bg-black-200 text-white">
      <SectionHeader title="Contact Us" />
      <div className="flex flex-col lg:flex-row gap-10 mt-10">
        <form className="flex-1">
          <div className="flex gap-5">
            <Input
              label="Email *"
              required
              type="email"
              placeholder="email@email.com"
            />
            <Input
              label="Name *"
              required
              type="text"
              placeholder="Your Name"
            />
          </div>
          <div className="mt-5">
            <Input label="Message" textarea required placeholder="Message..." />
          </div>
          <div className="mt-4 flex justify-end">
            <Button onClick={submitFormHandler} variant="primary">
              Submit
            </Button>
          </div>
        </form>
        <div className="flex-1 pt-0 lg:pt-10">
          <p>
            <span className="font-black uppercase">Email: </span>
            redhorsesec@gmail.com
          </p>
          <p>
            <span className="font-black uppercase">Phone: </span>+38 066 582 24 08
          </p>
          <p>
            <span className="font-black uppercase">Address: </span>
            26 Lesya Ukrainka, Kyiv, Ukraine
          </p>
        </div>
      </div>
    </section>
  );
};

export default Contact;
