export const services = [
  {
    title: "Penetration Testing",
    description:
      "Identify and mitigate vulnerabilities before malicious actors can exploit them. Our expert team simulates real-world attacks to ensure your defenses are robust and up-to-date.",
    link: "/services/penetration-testing",
  },
  {
    title: "Red Team as a Service",
    description:
      "Experience advanced adversary simulations designed to test your organization's security posture. Our red team conducts thorough assessments to uncover weaknesses and improve your resilience against sophisticated threats.",
    link: "/services/red-team-as-a-service",
  },
  {
    title: "Cyber Security Consultancy",
    description:
      "Receive strategic guidance and actionable solutions tailored to your unique security challenges. Our consultants work closely with your team to enhance your cybersecurity framework and practices.",
    link: "/services/cyber-security-consultancy",
  },
  {
    title: "Cyber Security Tools Development",
    description:
      "Empower your security measures with innovative tools developed by our specialists. We create custom solutions that address specific needs, ensuring your defenses are both effective and efficient.",
    link: "/services/cyber-security-tools",
  },
  {
    title: "OSINT Analytics",
    description:
      "Leverage open-source intelligence to gain insights and identify potential threats. Our OSINT analytics provide critical information to help you stay ahead of cyber risks and make informed security decisions.",
    link: "/services/osint-analytics",
  },
];
