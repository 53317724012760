import React from "react";
import SectionHeader from "../../components/SectionHeader";
import { services } from "../../utils/services";
import ServicesItem from "./ServicesItem";

const Services = () => {
  return (
    <section id="services" className="px-[5%] py-10 bg-black-300 text-white">
      <SectionHeader title="Services" />
      <div>
        <div className="mt-10 flex justify-center flex-wrap">
          {services.map((service) => (
            <ServicesItem service={service} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
